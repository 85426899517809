
import { HandleType, HandleTypeAsync, LocalEvent } from '../core/interfaces/CoreInterfaces';
import { Message } from "../misc/EventMessages";
import { AppState } from "../interfaces/AppState";
import { Attachment } from '../models/Attachment';
import { GetCategories, GetContext, GetLocalization } from '../misc/Requests';
import { Locale } from '../models/Locale';
import { UserManifestReport } from '../models/UserManifestReport';
import { SearchOptions } from '../models/SearchOptions';


export class MainHandler {

    constructor() {
        
        this.UpdateProfile.message = Message.UpdateProfile;
        this.UpdateAvatarFileId.message = Message.UpdateAvatarFileId;
        this.AddAttachmentToExchange.message = Message.AddAttachmentToExchange;
        this.RemoveAttachmentFromExchange.message = Message.RemoveAttachmentFromExchange;
        this.RefreshContext.message = Message.RefreshContext;
        this.RefreshCategories.message = Message.RefreshCategories;
        this.RefreshVocabulary.message = Message.RefreshVocabulary;
        this.SetProfileDebugQueyText.message = Message.SetProfileDebugQueyText;
        this.SaveProfileDebugResult.message = Message.SaveProfileDebugResult;
        this.SearchOptionsChange.message = Message.SearchOptionsChange;
    }
 
    UpdateProfile = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        state.person.displayName = arg.data.displayName;
        state.person.firstName = arg.data.firstName;
        state.person.lastName = arg.data.lastName;
        state.person.email = arg.data.email;
        state.person.primaryNumber = arg.data.primaryNumber;
        state.person.secondaryNumber = arg.data.secondaryNumber;
        state.person.timeZone = arg.data.timeZone;
        state.person.locationLatitude = arg.data.latitude;
        state.person.locationLongitude = arg.data.longitude;
        state.person.locationLabel = arg.data.locationLabel;

        return true;
    });

    UpdateAvatarFileId = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        state.person.avatarFileId = arg.data.fileId;
        return true;
    });

    AddAttachmentToExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data.id as string;
        const attachment = arg.data.attachment as Attachment;

        state.exchanges = state.exchanges.map(e => {
            if (e.id === exchangeId) {
                e.attachments.push(attachment);
            }
            return e;
        });

        return true;
    });

    RemoveAttachmentFromExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data.id as string;
        const attachmentId = arg.data.attachment as string;

        state.exchanges = state.exchanges.map(e => {
            if (e.id === exchangeId) {
                e.attachments = e.attachments.filter(x => x.fileId !== attachmentId);
            }
            return e;
        });

        return true;
    });

    RefreshContext = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const result = await GetContext();
        if (result.success) {
            state.person = result.context.person;
            state.groups = result.context.groups;
            state.groupPath = result.context.groupPath;
            return Promise.resolve(true);
        }
    });

    RefreshCategories = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const locale = arg.data.locale as Locale;
        const vocab = await GetLocalization(locale);
        state.localization = vocab;
        const categories = await GetCategories();
        state.categories = categories;
        return Promise.resolve(true);
    });

    RefreshVocabulary = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const locale = arg.data.locale as Locale;
        const vocab = await GetLocalization(locale);
        state.localization = vocab;
        return Promise.resolve(true);
    });


    SetProfileDebugQueyText = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        state.userDebugQuery = arg.data.query as string;
        return true;
    });

    SaveProfileDebugResult = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        state.userManifest = arg.data as UserManifestReport;
        return true;
    });

    SearchOptionsChange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {
        const options = arg.data as SearchOptions;
        state.searchOptions = options;
        return true;
    });
}