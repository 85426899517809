import { useContext } from "react";
import { AppContext } from "../interfaces/AppContext";
import { StorageItems } from "../services/LocalStorageService";

export type StorageValueGetter = (key: StorageItems) => any | null;
export type StorageValueSetter = (key: StorageItems, value: any) => void;

export const useLocalStorage = (): [StorageValueSetter, StorageValueGetter] => {

    const { person } = useContext(AppContext);

    const getPersonalizedKey = (key: string): string => {
        const pid = person.personId;
        return `${pid}.${key}`;
    };

    function setValue(key: StorageItems, value: any): void {
        const k = getPersonalizedKey(key);      
        const obj = JSON.stringify(value);
        localStorage.setItem(k, obj);
    }

    function getValue(key: StorageItems): any | null {
        const k = getPersonalizedKey(key);
        const data = localStorage.getItem(k);
        if(typeof data === "undefined" || data === null){
            return null;
        }

        try{
            return JSON.parse(data);
        }
        catch{
        
            return null;
        }
    }

    return [setValue, getValue];

}