import { ReactElement, useContext, useEffect, useState } from "react";
import style from "./manage.module.scss";
import { GetRegions } from "../../../misc/Requests";
import { Location } from "../../../models/Location";
import Select from "react-select";
import { OptionType } from "../../../interfaces/OptionType";
import axios from "axios";
import { app } from "../../..";
import { TransactionResult } from "../../../models/TransactionResult";
import { useNavigate, useParams } from "react-router";
import classNames from "classnames";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { PubSubTopic } from "../../../misc/Constants";
import { Message } from "../../../misc/EventMessages";

export const GroupForm = (): ReactElement => {

    const { regionId } = useParams();

    const { localization } = useContext(LocalizationContext);

    const [regions, setRegions] = useState<OptionType[]>([]);
    const [region, setRegion] = useState<OptionType>(null);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");

    const nav = useNavigate();



    useEffect(() => {
        const regions = GetRegions("");
        regions.then(result => {
            if (result !== null) {

                const options = result.map(r => { return { value: r.id, label: r.name } as OptionType });

                setRegions(x => {
                    return [...options];
                });

                const selectedRegion = options.find(r => r.value === regionId);
                if (typeof selectedRegion !== "undefined") {
                    setRegion(selectedRegion);
                }
            }
        });

    }, []);

    const createGroup = (): void => {
        setMessage("");

        const result = axios.post(`${app.apiBasePath}/admin/group`, {
            name: name,
            parentId: region.value
        });

        result.then(r => {
            const response = r.data as TransactionResult;
            if (response.success) {
                setMessage(name + " group created!");
                setName("");
                requestContextUpdate();
                nav(`/admin/groups/${regionId}`);

            }
            else {
                setMessage(localization[response.errorMessage]);
            }
        })
    };

    const requestContextUpdate = (): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RefreshContext
        });
    };


    return (
        <div className={style.container}>
            <div className={classNames("form", style.content)}>

                <h2>Ajouter un groupe</h2>

                <div className={style.form}>
                    <div className={style.row}>
                        <label>Region</label>
                        <Select
                            isDisabled={region !== null}
                            value={region}
                            onChange={e => setRegion(e)}
                            options={regions}
                        />
                    </div>

                    <div className={style.row}>
                        <label>Nom du groupe</label>
                        <input value={name} onChange={e => setName(e.target.value)} className={style.control} type="text" />
                    </div>

                    {
                        message !== "" &&
                        <div className={style.message}>
                            <span>{message}</span>
                        </div>
                    }
                    <div className={classNames(style.row, style.buttons)}>
                        <button onClick={() => nav(`/admin/groups/${regionId}`)} className="btn cancel">{localization["cancel"]}</button>
                        <button onClick={createGroup} disabled={name.length < 2 || region === null} className="btn action">{localization["submit"]}</button>
                    </div>

                </div>
            </div>
        </div>
    );
}