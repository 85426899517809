import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { app } from "..";
import { HandleType, HandleTypeAsync, LocalEvent } from "../core/interfaces/CoreInterfaces";
import { AppState } from "../interfaces/AppState";
import { Message } from "../misc/EventMessages";
import { IsNullOrWhiteSpace, IsUndefinedOrNull, StringIsNullOrEmpty } from "../misc/Utilities";
import { ExchangeState } from "../models/Enums";
import { FavoriteExchange } from "../models/FavoriteExchange";
import { Exchange } from "../models/Exchange";
import { SearchOptions } from "../models/SearchOptions";
import { PubSubTopic } from "../misc/Constants";



export class ExchangeHandler {

    constructor() {
        this.DeleteDraft.message = Message.DeleteDraft;
        this.UpdateInboxExchanges.message = Message.UpdateInboxExchanges;
        this.UnpublishExchange.message = Message.UnpublishExchange;
        this.UpdateExchangeStatePublished.message = Message.UpdateExchangeStatePublished;
        this.SearchForExchanges.message = Message.SearchForExchanges;
        this.AddFavoriteExchange.message = Message.AddFavoriteExchange;
        this.RemoveFavoriteExchange.message = Message.RemoveFavoriteExchange;
    }

    DeleteDraft = <HandleTypeAsync>((arg: LocalEvent, state: AppState): Promise<boolean> => {

        const exchangeId = arg.data.id as string;

        if (IsNullOrWhiteSpace(exchangeId)) {
            return Promise.reject();
        }

        return new Promise<boolean>((resolve, reject) => {

            const deleteRequest = axios.delete(`${app.apiBasePath}/exchange/${exchangeId}`);


            deleteRequest.then(d => {
                state.exchanges = state.exchanges.filter(e => e.id !== exchangeId);
                resolve(true);
            });

        });
    });

    UpdateInboxExchanges = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchange = arg.data as Exchange;

        if (!IsUndefinedOrNull(exchange)) {

            const exchangeExist = state.exchanges.some(p => p.id === exchange.id);

            if (exchangeExist) {
                state.exchanges = state.exchanges.filter(p => p.id !== exchange.id);
            }

            state.exchanges = [exchange, ...state.exchanges];
        }

        return true;
    });

    UnpublishExchange = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {
        const exchangeId = arg.data.exchangeId;
        if (StringIsNullOrEmpty(exchangeId)) {
            return Promise.reject("exchangeId");
        }

        const result = await axios.post(`${app.apiBasePath}/exchange/unpublish`, { exchangeId: exchangeId });

        if (result.status === StatusCodes.OK) {
            state.exchanges = state.exchanges.map(e => {
                if (e.id === exchangeId) {
                    e.state = ExchangeState.Draft;
                }
                return e;
            });
        }

        return true;
    });

    UpdateExchangeStatePublished = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data as string;

        if (!StringIsNullOrEmpty(exchangeId)) {

            const exchange = state.exchanges.find(p => p.id === exchangeId);

            if (!IsUndefinedOrNull(exchange)) {
                state.exchanges = state.exchanges.filter(p => p.id !== exchangeId);
            }
            exchange.state = ExchangeState.Published;
            state.exchanges = [exchange, ...state.exchanges];
        }

        return true;
    });

    AddFavoriteExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const model = arg.data as FavoriteExchange;

        if (!IsUndefinedOrNull(model)) {
            const current = state.favoriteExchanges.filter(x => x.exchangeId !== model.id);
            if (model.isFavorite) {
                current.push(model);
                state.favoriteExchanges = current;
            }
        }

        return true;
    });

    RemoveFavoriteExchange = <HandleType>((arg: LocalEvent, state: AppState): boolean => {

        const exchangeId = arg.data as string;

        if (!IsNullOrWhiteSpace(exchangeId)) {
            const current = state.favoriteExchanges.filter(x => x.exchangeId !== exchangeId);
            state.favoriteExchanges = current;
        }

        return true;
    });

    SearchForExchanges = <HandleTypeAsync>(async (arg: LocalEvent, state: AppState): Promise<boolean> => {

        const model = arg.data as SearchOptions;

        try {
            const result = await axios.post(`${app.apiBasePath}/search/exchanges`, {
                categoryId: model.categoryId,
                query: model.queryText,
                groups: model.locationIds,
                distanceMeters: model.radius * 1000,
                includeResultsOffgidExchanges: model.includeResultsOffgidExchanges,
                ExchangeType: model.exchangeType,
                IncludeMyResults: model.includeMyExchanges
            });

            state.searchResults = result.data;

            window.setTimeout(() => {
                PubSub.publish("result.events");
            }, 10);

            return Promise.resolve(true);
        }
        catch {
            return Promise.resolve(false);
        }
    });
}