import classNames from "classnames";
import moment from "moment";
import { ReactElement, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { Attachment } from "../../models/Attachment";
import { ExchangeType, ExchangeResult } from "../../models/Exchange";
import { Favorites } from "../Favorites/Favorites";
import { Location } from "../../models/Location";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";
import { app } from "../..";
import noimg from "../../images/no-image.jpg";
import style from "./search-results.module.scss";

export type SearchResultModel = {
    distanceMeters: number;
    postedById: string;
    postedByName: string;
    exchange: ExchangeResult;
    id: string;
    file?: Attachment;
    locations: Location[];
    exchangeType: ExchangeType;

};

const Result = ({ model, radiusAvailable }: { model: SearchResultModel, radiusAvailable: boolean }): ReactElement => {

    const { favorites } = useContext(AppContext);
    const { localization } = useContext(LocalizationContext);
    return (
        <div className={classNames(style.result, model.exchangeType === "demande" ? style.demande : null)}>

            <div className={style.thumb}>
                {
                    !IsNullOrWhiteSpace(model.file?.fileId) &&
                    <Link to={`/exchanges/${model.id}`}>
                        <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${model.file?.fileId}`} alt="image non trouvé" />
                    </Link>
                }
                {
                    IsNullOrWhiteSpace(model.file?.fileId) &&
                    <Link to={`/exchanges/${model.id}`}>
                        <img src={noimg} />
                    </Link>
                }
            </div>

            <div className={style.exchange}>
                <Link to={`/exchanges/${model.id}`}>
                    {
                        model.exchangeType === "offer" &&
                        <h5>{model.exchange.title}</h5>
                    }
                    {
                        model.exchangeType === "demande" &&
                        <h5>{model.exchange.title} (demande)</h5>
                    }

                </Link>
                <div className={style.b}>
                    <strong>{localization["publishBy"]} </strong><Link to={`exchanges/u/${model.postedById}`}>{model.postedByName}</Link>
                </div>
                <div className={style.b}>
                    <strong>publié</strong><span>{moment(model.exchange.publishedOn).fromNow()}</span>
                </div>

                {
                    !IsNullOrWhiteSpace(model.exchange.updatedOn) &&
                    <div className={style.b}>
                        <strong>{localization["lastUpdated"]}</strong><span>{moment(model.exchange.updatedOn).fromNow()}</span>
                    </div>
                }

                <div className={classNames(style.b, style.f)}>
                    {
                        radiusAvailable &&
                        <span className={classNames(style.tag, style.distance)}>{Math.round((model.distanceMeters / 1000) * 10) / 10} km</span>
                    }

                    <Favorites isSet={favorites.some(p => p.exchangeId === model.id)} resourceId={model.id} />
                </div>
                <div className={classNames(style.b, style.f)}>
                    {
                        model.locations.map(p => {
                            return <span key={p.id} id={p.id} className={classNames(style.tag, style.location)}>{p.name}</span>
                        })
                    }
                </div>
            </div>
        </div >
    );
};

export interface SearchResultsProps {
    results: SearchResultModel[];
    radiusAvailable: boolean;
}

export const SearchResults = (props: SearchResultsProps): ReactElement => {

    const { results, radiusAvailable } = props;
    const { localization } = useContext(LocalizationContext);
    return (
        <div className={style.results}>
            {
                results?.map(p => {
                    return <Result radiusAvailable={radiusAvailable} key={p.exchange.uniqueId} model={p} />
                })
            }
            {
                results !== null &&
                results.length === 0 &&
                <div className={style.nothing}>
                    <p>{localization["noResults"]}</p>
                </div>
            }
        </div>
    );
};