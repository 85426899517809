import { ReactElement, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ReactLoading from "react-loading";
import style from "./groupmanage.module.scss";
import axios from "axios";
import { app } from "../../..";
import { Group } from "../../../models/Group";
import { GroupUser } from "../../../models/GroupUser";
import { HasPrivilege } from "../../../misc/Utilities";
import { GroupPrivileges } from "../../../models/Enums";
import { PubSubTopic } from "../../../misc/Constants";
import { Message } from "../../../misc/EventMessages";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StatusCodes } from "http-status-codes";



export const ManageGroupForm = (): ReactElement => {
    const { groupId } = useParams();

    const [group, setGroup] = useState<Group>(null);
    const [people, setPeople] = useState<GroupUser[]>([]);

    const [loading, setLoading] = useState(true);
    const [renaming, setRenaming] = useState(false);

    const { localization } = useContext(LocalizationContext);

    const [isEdit, setIsEdit] = useState(false);

    const [tempGroupName, setTempGroupName] = useState("");

    const nav = useNavigate();

    useEffect(() => {

        loadDataFromServer();
    }, []);

    const loadDataFromServer = (): void => {
        const groupInfo = axios.get(`${app.apiBasePath}/admin/group/info?groupId=${groupId}`);
        const users = axios.get(`${app.apiBasePath}/admin/group/listusers?groupId=${groupId}`);
        Promise.all([groupInfo, users]).then(([group, users]) => {
            setGroup(group.data);
            setPeople(users.data);
            setLoading(false);
        });

    };

    const requestContextUpdate = (): void => {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.RefreshContext
        });
    };

    const makeFacilitator = (usr: GroupUser): void => {
        if (window.confirm(`promouvoir ${usr.firstName} en tant que facilitateur(trice) du groupe?`)) {
            axios.post(`${app.apiBasePath}/admin/group/updateprivileges`, {
                personId: usr.personId,
                groupId: groupId,
                justification: '',
                privileges: usr.privileges | GroupPrivileges.Facilitator
            }).then(() => {
                loadDataFromServer();
                requestContextUpdate();
            });
        }
    };

    const removeFacilitator = (usr: GroupUser): void => {
        if (window.confirm(`Rétirer le pouvoir de facilitateur(trice) pour ${usr.firstName}?`)) {
            axios.post(`${app.apiBasePath}/admin/group/updateprivileges`, {
                personId: usr.personId,
                groupId: groupId,
                justification: '',
                privileges: GroupPrivileges.CanPost | GroupPrivileges.CanSearch | GroupPrivileges.Observer
            }).then(() => {
                loadDataFromServer();
                requestContextUpdate();
            });
        }
    };


    const deleteGroup = (): void => {
        if (window.confirm(`Supprimer ${group.name}?`)) {
            axios.post(`${app.apiBasePath}/admin/group/delete`, {
                groupId: groupId
            }).then(() => {
                requestContextUpdate();
                nav("/admin/groups");
            });
        }
    };

    const editGroupTitle = (): void => {
        setTempGroupName(group.name);
        setIsEdit(true);
    };

    const requestRenameGroup = (): void => {
        setRenaming(true);

        const result = axios.post(`${app.apiBasePath}/admin/group/rename`,{
            groupId: group.id,
            groupName: tempGroupName
        });

        result.then(v=>{
            setRenaming(false);
            if(v.status === StatusCodes.BAD_REQUEST){
               // console.log(v.data);
            }
            else{
                loadDataFromServer();
                setIsEdit(false);
            }
        })
    };

    const validGroupName = (value: string): boolean => {

        if (value.trim().length < 3)
            return false;

        return true;
    }


    return (
        <div className={style.container}>

            <div className="form">
                {
                    loading &&
                    <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                }

                {
                    group !== null &&
                    <div className="row">
                        {
                            !isEdit &&
                            <div className={style.groupname}>
                                <h2>{group.name}</h2>
                                <button onClick={editGroupTitle}>
                                    <FontAwesomeIcon icon={"pen"} />
                                </button>
                            </div>
                        }
                        {
                            isEdit &&
                            <div className={style.groupnameedit}>
                                <h2>Renommer {group.name}</h2>
                                <input
                                    disabled={renaming}
                                    onKeyDown={key => key.code === "Escape" && setIsEdit(false)}
                                    type="text"
                                    maxLength={120}
                                    className="form-control"
                                    value={tempGroupName}
                                    onChange={e => setTempGroupName(e.target.value)}
                                />

                                <div className={style.buttons}>
                                    <button disabled={renaming} onClick={() => setIsEdit(false)} className="btn cancel">{localization["cancel"]}</button>
                                    <button disabled={!validGroupName(tempGroupName) || renaming} onClick={requestRenameGroup} className="btn save">{localization["save"]}</button>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    !loading &&
                    people.length === 0 &&

                    !isEdit &&
                    <button onClick={deleteGroup} className="btn cancel">{localization["delete"]?.toLowerCase()} {group.name}</button>
                }
                {
                    !loading &&
                    <div className={style.list}>
                        {
                            people.map(p => {
                                return (
                                    <div className={style.user} key={p.personId}>
                                        <div className={style.ug}>
                                            <span>{p.firstName} {p.lastName}</span>

                                            {
                                                !HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                                <a onClick={() => makeFacilitator(p)}>{localization["promoteLabel"]}</a>
                                            }
                                            {
                                                HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                                <a className={style.remove} onClick={() => removeFacilitator(p)}>retirer</a>
                                            }
                                        </div>
                                        <div className={style.email}>
                                            <span>{p.emailAddress}</span>
                                        </div>
                                        <div className={style.tags}>
                                            {
                                                HasPrivilege(p.privileges, GroupPrivileges.Facilitator) &&
                                                <span className={style.g}>{localization["facilitatorLabel"]}</span>
                                            }
                                            {
                                                HasPrivilege(p.privileges, GroupPrivileges.CanPost) &&
                                                <span>{localization["publish"]?.toLowerCase()}</span>
                                            }

                                            {
                                                HasPrivilege(p.privileges, GroupPrivileges.CanSearch) &&
                                                <span>{localization["search"]?.toLowerCase()}</span>
                                            }


                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

            </div>

        </div>
    );
};