export enum Message{
    UpdateProfile = 202,
    UpdateAvatarFileId,

    UpdateInboxExchanges,
    DeleteDraft,
    UnpublishExchange,
    UpdateExchangeStatePublished,
    AddFavoriteExchange,
    RemoveFavoriteExchange,
    AddAttachmentToExchange,
    RemoveAttachmentFromExchange,
    RefreshContext,
    RefreshCategories,
    RefreshVocabulary,
    SetProfileDebugQueyText,
    SaveProfileDebugResult,
    SearchForExchanges,
    SearchOptionsChange,
    
    SocketCommand,
    SocketNotification,
    SocketEvent
}