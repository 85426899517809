import { AppContextType } from "../interfaces/AppContext";
import { Person } from "../models/Person";

export type StorageItems = "search-options" | "__search_results" | "collapseSearch" | "admin-invites-lastgroup" | "results-scroll-pos";

export class LocalStorageService {

    person : Person;

    constructor(context: AppContextType) {
        this.person = context.person;
    }

    getFromStorage<T>(key: StorageItems): T | null {
        const k = this.getPersonalizedKey(key);
        const data = localStorage.getItem(k);
        if(typeof data === "undefined" || data === null){
            return null;
        }

        try{
            return JSON.parse(data);
        }
        catch{
            console.log("catch", k)
            return null;
        }
    }

    setToStorage(key: StorageItems, obj: any): void {
        const k = this.getPersonalizedKey(key);      
        const _obj = JSON.stringify(obj);
        localStorage.setItem(k, _obj);
    }

    private getPersonalizedKey(key: string): string {
        const pid = this.person.personId;
        return `${pid}.${key}`;
    };
}