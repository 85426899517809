import { ReactElement, useEffect, useState } from "react";

import style from "./groupmanage.module.scss";
import Select from "react-select";
import classNames from "classnames";
import axios from "axios";
import { app } from "../../..";
import { StatusCodes } from "http-status-codes";
import { OptionType } from "../../../interfaces/OptionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IsNullOrWhiteSpace } from "../../../misc/Utilities";
import { LocalizationContext } from "../../../interfaces/AppContext";
import { useContext } from "react"

type Group = {
    id: string;
    name: string;
};

type Region = {
    id: string;
    name: string;
    groups: Group[];
};

type Province = {
    id: string;
    name: string;
    regions: Region[];
};

export const GroupManagement = (): ReactElement => {

    const { regionId } = useParams();

    const [province, setProvince] = useState<OptionType>();
    const [regions, setRegions] = useState<OptionType[]>();

    const [selectedRegion, setSelectedRegion] = useState<OptionType>(null);

    const { localization } = useContext(LocalizationContext);

    const [groups, setGroups] = useState<OptionType[]>();

    const [responseData, setResponseDatga] = useState<Region[]>(null);

    const nav = useNavigate();

    useEffect(() => {

        axios.get(`${app.apiBasePath}/admin/province/locations`)
            .then(result => {
                if (result.status === StatusCodes.OK) {
                    const pr = result.data as Province;

                    setProvince({
                        value: pr.id,
                        label: pr.name
                    });

                    setRegions(old => {

                        const tt: OptionType[] = pr.regions.map(r => {
                            return {
                                value: r.id,
                                label: r.name
                            };
                        });

                        return [...tt]
                    });

                    setResponseDatga(d => {
                        return [...pr.regions];
                    });

                    if (!IsNullOrWhiteSpace(regionId)) {
                        const selectedRegion = pr.regions.find(r => r.id === regionId)
                        if (selectedRegion) {
                            setSelectedRegion({
                                value: selectedRegion.id,
                                label: selectedRegion.name
                            });
                        }

                    }
                }
            });
    }, [])


    return (
        <div className={style.container}>
            <div className="form">
                <h1>{localization["groupManagement"]}</h1>
            </div>
            <div className={classNames("form", style.groupform)}>
                <div className="row">
                    <label>{localization["countryLabel"]}</label>
                    <Select isDisabled={true} value={{ value: "", label: "Canada" }} />
                </div>
                <div className="row">
                    <label>{localization["provinceLabel"]}</label>
                    <Select isDisabled={true} value={province} />
                </div>
                <div className="row">
                    <label>{localization["regionLabel"]}</label>
                    <Select value={selectedRegion} onChange={g => setSelectedRegion(g)} options={regions} />
                </div>

                {
                    responseData !== null &&
                    selectedRegion !== null &&
                    <div className={style.collection}>
                        <div className={style.h}>
                            <h3>{localization["groupsInregion"]}: {selectedRegion.label}</h3>
                            <button onClick={() => nav(`/admin/groups/create/${selectedRegion.value}`)} className="btn save2">
                                <FontAwesomeIcon icon="plus" />
                                <span>ajouter un groupe</span>
                            </button>
                        </div>
                        <ul>
                            {
                                responseData.filter(g => g.id === selectedRegion.value)[0].groups
                                    .sort((g1, g2) => g1.name.localeCompare(g2.name))
                                    .map(r => {
                                        return <li key={r.id}><Link to={`/admin/groups/manage/${r.id}`}>{r.name}</Link></li>
                                    })
                            }
                        </ul>
                    </div>
                }
            </div>

        </div>
    );
};