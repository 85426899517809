import React, { useEffect, useRef, useState } from 'react';
import { Master, MasterProps } from './pages/Master/Master';
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    createRoutesFromElements,
    Navigate
} from "react-router-dom";

import { AppContext, LocalizationContext } from './interfaces/AppContext';
import { AppState } from './interfaces/AppState';
import { app } from '.';
import { PubSubTopic } from './misc/Constants';
import { ExchangeForm } from './pages/post/ExchangeForm';
import { CategoryManagement } from './pages/admin/CategoryManagement/CategoryManagement';
import { ExchangeFilesForm } from './pages/post/ExchangeFilesForm';
import { NavItem } from './interfaces/NavItem';
import { ExchangeState, GroupPrivileges, Role } from './models/Enums';
import { AdminMenuOptions, DevMenuOptions, HomeMenuOptions, ModeratorMenuOptions, MyExchangesMenuOptions } from './store/Navigation';
import { ProfileView } from './pages/profile/ProfileView';
import { ProfileEdit } from './pages/profile/ProfileEdit';
import { ChangePassword } from './pages/profile/ChangePassword';
import { GroupManage } from './pages/Facilitators/GroupManage';
import { ExchangeLoader } from './components/ExchangeLoader';
import { ExchangeView } from './pages/ExchangeView/ExchangeView';
import { FavoriteExchange } from './models/FavoriteExchange';
import { FavoritesPage } from './pages/Favorites/FavoritesPage';
import { Manage } from './pages/admin/Management/Manage';
import { GroupsManagement } from './pages/admin/Management/GroupsManagement';
import { GroupForm } from './pages/admin/Management/GroupFrom';
import { UserExchanges } from './pages/UserExchanges/UserExchanges';
import { MyExchanges } from './pages/MyExchanges/MyExchanges';
import { HasPrivilege, HasPrivilegeOrDev, IsNullOrWhiteSpace } from './misc/Utilities';
import { UserManagement } from './pages/admin/UserManage/UserManagement';
import { UserForm } from './pages/admin/UserManage/UserForm';
import { UserLoader } from './pages/admin/UserManage/UserLoader';
import { EditUserForm } from './pages/admin/UserManage/EditUserForm';
import { GroupManagement } from './pages/admin/GroupManagement/GroupManagement';
import { ManageGroupForm } from './pages/admin/GroupManagement/ManageGroupForm';
import { Person } from './models/Person';
import { Group } from './models/Group';
import { Exchange } from './models/Exchange';
import { VocabularyPage } from './pages/admin/VocabularyManagement/VocabularyPage';
import { VocabularyForm } from './pages/admin/VocabularyManagement/VocabularyForm';
import { CategoryForm } from './pages/admin/CategoryManagement/CategoryForm';
import { UpdateCategoryForm } from './pages/admin/CategoryManagement/UpdateCategoryForm';
import { Dictionary } from './interfaces/Dictionary';
import { LogsPage } from './pages/Developer/LogsPage';
import { UserDebugView } from './pages/Developer/UserDebugView';
import { Socket, io } from 'socket.io-client';
import { Message } from './misc/EventMessages';
import { SocketMessage } from './models/SocketMessage';
import { Tests } from './pages/Developer/Tests';
import { SearchOptions } from "./models/SearchOptions";
import { SearchResultModel } from './components/SearchResults/SearchResults';
import { AdminInviteControl } from "./pages/admin/UserManage/AdminInviteControl";
import { JoinGroup } from './pages/Join/JoinGroup';
import { AgreementView } from './pages/Agreements/AgreementView';
import moment from 'moment';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';


import './styles/app.global.scss';

const App = ({ localization, socketsToken }: { localization: Dictionary<string>, socketsToken: string }): JSX.Element => {

    const [navigation, setNavigation] = useState([] as NavItem[]);
    const [person, setPerson] = useState(app.store.state.person);
    const [groups, setGroups] = useState(app.store.state.groups);
    const [localGroups, setLocalGroups] = useState(app.store.state.localGroups);
    const [groupPath, setGroupPath] = useState(app.store.state.groupPath);
    const [favorites, setFavorites] = useState<FavoriteExchange[]>(app.store.state.favoriteExchanges);
    const [exchanges, setExchanges] = useState(app.store.state.exchanges);
    const [categories, setCategories] = useState(app.store.state.categories);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [token, setToken] = useState(socketsToken);

    const lastTokenRefreshRef = useRef(new Date());
    const [localizationDictionary, setLocalizationDictionary] = useState(localization);
    const [debugQuery, setDebugQuery] = useState(app.store.state.userDebugQuery);
    const [userManifest, setUserManifest] = useState(app.store.state.userManifest);

    const [searchOptions, setSearchOptions] = useState<SearchOptions>(app.store.state.searchOptions);

    const [searchResults, setSearchResults] = useState<Array<SearchResultModel>>(app.store.state.searchResults);

    const buildNavMenu = (personInfo: Person, groups: Group[], exchanges: Exchange[], favoriteExchanges: FavoriteExchange[]): void => {

        const nav = [HomeMenuOptions(), MyExchangesMenuOptions(exchanges, localization, favoriteExchanges)];

        const moderatorOptions = ModeratorMenuOptions(groups);
        if (moderatorOptions !== null) {
            nav.push(moderatorOptions);
        }

        if (!HasPrivilege(personInfo.role, Role.Developper) &&
            (HasPrivilege(personInfo.role, Role.UserManagement) ||
                HasPrivilege(personInfo.role, Role.GroupManagement) ||
                HasPrivilege(personInfo.role, Role.CategoryManagement))) {
            nav.push(AdminMenuOptions(personInfo.role, localization));
        }
        else if (HasPrivilege(personInfo.role, Role.Developper)) {

            nav.push(AdminMenuOptions(personInfo.role, localization));
            nav.push(DevMenuOptions());
        }

        setNavigation(nav);
    };


    const onStoreDataChanged = (msg, state: AppState): void => {

        setTimeout(() => {

            setCategories(app.store.state.categories);
            setExchanges(state.exchanges);
            setPerson(state.person);
            setGroups(state.groups);
            setLocalGroups(state.localGroups);
            setFavorites(state.favoriteExchanges);
            setLocalizationDictionary(state.localization);
            setDebugQuery(state.userDebugQuery);
            setUserManifest(state.userManifest);
            setSearchOptions(prev => {
                return { ...state.searchOptions };
            });

            setSearchResults(prev => {
                return [...state.searchResults];
            });

            buildNavMenu(state.person, state.groups, state.exchanges, state.favoriteExchanges);
        }, 350);

    };

    useEffect(() => {

        buildNavMenu(app.store.state.person, app.store.state.groups, app.store.state.exchanges, app.store.state.favoriteExchanges);

        const ref = PubSub.subscribe(PubSubTopic.Changes, onStoreDataChanged);

        return () => {
            PubSub.unsubscribe(ref);
        }
    }, []);


    useEffect(() => {

        let socket : Socket = null;

        if (false && HasPrivilege(person.role, Role.Developper)) {

            socket = io(app.wsUrl, {
                reconnectionDelayMax: 1000,
                autoConnect: true,
                extraHeaders: {
                    "auth": `${token}`
                }
            });

            socket.on("connect_error", (err) => {
                let duration = moment.duration(moment().diff(lastTokenRefreshRef.current));
                const minutes = duration.asMinutes();

                if (minutes > 5) {
                    const tokenRequest = axios.get(`${app.apiBasePath}/auth/token`, { validateStatus: e => e > 500 });
                    tokenRequest.then(result => {
                        if (typeof result === "undefined" || result.status === StatusCodes.UNAUTHORIZED) {
                            window.location.reload();
                        }
                        else {

                            if (!IsNullOrWhiteSpace(result.data)) {
                                setToken(result.data);
                                lastTokenRefreshRef.current = new Date();
                            }
                        }
                    });
                }
            });

            socket.on('command', (response: SocketMessage) => {
 
                let msg = -1;

                switch (response.event) {
                    case "command":
                        msg = Message.SocketCommand;
                        break;
                    case "event":
                        msg = Message.SocketEvent;
                        break;
                    case "notification":
                        msg = Message.SocketNotification;
                        break;
                    default:
                        break;
                }
                if (msg > 0) {
                    PubSub.publish(PubSubTopic.Action, {
                        id: msg,
                        data: response.message
                    });
                }
            });
        }

    }, []);

    const masterProps: MasterProps = {
        navigation: navigation,
        mobileMenuVisible: mobileMenuVisible,
        onOpenMobileMenu: () => setMobileMenuVisible(true),
        onCloseMobileMenu: () => setMobileMenuVisible(false)
    };

    const hasAdminOptions = (roles: Role): boolean => {
        return HasPrivilege(roles, Role.Developper)
            || HasPrivilege(roles, Role.UserManagement)
            || HasPrivilege(roles, Role.GroupManagement)
            || HasPrivilege(roles, Role.CategoryManagement);
    };

    const hasAccessToModeratorUrls = groups.some(g => HasPrivilege(g.privilege, GroupPrivileges.Facilitator));

    const router = createBrowserRouter(
        createRoutesFromElements(

            <Route path={"/"} element={<Master {...masterProps} />}>
                {
                    HasPrivilege(person.role, Role.Developper) &&
                    <Route path={"dev"}>
                        <Route path={"logs"} element={<LogsPage />} />
                        <Route path={"userprofile"} element={
                            <UserDebugView
                                query={debugQuery}
                                report={userManifest}
                                categories={categories} />} />
                        <Route path="test" element={<Tests />} />
                    </Route>
                }

                <Route path={"join"}>
                    <Route index path=":code" element={<JoinGroup />} />
                </Route>

                <Route index element={<Home searchResults={searchResults} {...searchOptions}
                    categories={categories}
                />} />

                <Route path={"profile"}>
                    <Route index element={<ProfileView />} />
                    <Route path="edit" element={<ProfileEdit />} />
                    <Route path="changepwd" element={<ChangePassword />} />
                </Route>

                <Route path={"/drafts"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myDrafts"
                            noItemsKey="noDraftsMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Draft)}
                            categories={categories} />} />
                </Route>

                <Route path={"/exchanges"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myOffers"
                            noItemsKey="noOffersMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Published && p.exchangeType === "offer")}
                            categories={categories} />} />
                </Route>
                <Route path={"/demandes"}>
                    <Route index element={
                        <MyExchanges
                            titleKey="myDemands"
                            noItemsKey="noOffersMessage"
                            exchanges={exchanges.filter(p => p.state === ExchangeState.Published && p.exchangeType === "demande")}
                            categories={categories} />} />
                </Route>

                <Route path={"/favorites"}>
                    <Route index element={
                        <FavoritesPage items={favorites} />} />
                </Route>

                <Route path="exchanges">
                    <Route path=":id" element={<ExchangeView categories={categories} />} />
                    <Route path="u">
                        <Route path=":personid" element={<UserExchanges />} />
                    </Route>
                </Route>

                <Route path={"post"}>
                    <Route path={"exchange"} element={<ExchangeForm categories={categories} />} />
                    <Route path={"exchange/:exchangeid"} element={
                        <ExchangeLoader categories={categories} />
                    } />
                    <Route path={"exchange/:exchangeid/files"} element={
                        <ExchangeFilesForm categories={categories} />
                    } />
                </Route>

                <Route path={"group"}>
                    {
                        (hasAccessToModeratorUrls ||
                            HasPrivilege(person.role, Role.Developper)) &&
                        <Route path="manage/:groupid">
                            <Route index element={<GroupManage />} />
                            <Route path=":option" element={<GroupManage />}>
                                <Route path=":suboption" element={<GroupManage />} />
                            </Route>
                        </Route>
                    }
                </Route>

                <Route path={"/login"}>
                    <Route index element={<Login />} />
                </Route>

                {
                    hasAdminOptions(person.role) &&
                    <Route path="/admin">
                        {
                            HasPrivilegeOrDev(person.role, Role.UserManagement) &&
                            <Route path={"users"}>
                                <Route index element={<UserManagement />} />
                                <Route path={":personId"} element={<UserLoader><EditUserForm /></UserLoader>} />
                                <Route path="create" element={<UserForm />} />
                            </Route>
                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.UserManagement) &&
                            <>
                                <Route path="invitations">
                                    <Route index element={<AdminInviteControl />} />
                                    <Route path={":suboption"} element={<AdminInviteControl />} />
                                </Route>

                                <Route path="invite">
                                    <Route path={":groupid"} element={<AdminInviteControl />} />
                                </Route>
                            </>
                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.GroupManagement) &&
                            <Route path={"groups"}>
                                <Route index element={<GroupManagement />} />
                                <Route path={":regionId"} element={<GroupManagement />} />
                                <Route path="create">
                                    <Route path=":regionId" element={<GroupForm />} />
                                </Route>
                                <Route path="manage">
                                    <Route path=":groupId" element={<ManageGroupForm />} />
                                </Route>

                            </Route>

                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.CategoryManagement) &&
                            <Route path={"categories"}>
                                <Route index element={<CategoryManagement />} />
                                <Route path=":categoryId" element={<UpdateCategoryForm categories={categories} />} />
                                <Route path="add" element={<CategoryForm />} />
                            </Route>
                        }
                        {
                            HasPrivilegeOrDev(person.role, Role.VocabularyManagement) &&
                            <Route path={"vocabulary"}>
                                <Route index element={<VocabularyPage />} />
                                <Route path={":locale"} element={<VocabularyPage />} />
                                {
                                    HasPrivilege(person.role, Role.Developper) &&
                                    <Route path="add">
                                        <Route index element={<VocabularyForm />} />
                                        <Route path={":locale"} element={<VocabularyForm />} />
                                    </Route>
                                }

                            </Route>
                        }
                    </Route>

                }

                {
                    HasPrivilege(person.role, Role.Developper) &&
                    <Route path={"dev"}>
                        <Route index element={<div></div>} />
                        <Route path={"management"}>
                            <Route index element={<Manage />} />
                            <Route path={"groups"}>
                                <Route index element={<GroupsManagement />} />
                                <Route path="add" element={<GroupForm />} />
                            </Route>
                        </Route>
                    </Route>
                }

                <Route path="codeofconduct" element={<AgreementView readOnly={true} />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        )
    );

    return (

        <AppContext.Provider value={{
            person: person,
            groups: groups,
            localGroups: localGroups,
            groupPath: groupPath,
            favorites: favorites
        }}>
            <LocalizationContext.Provider value={{
                localization: localizationDictionary
            }}>
                <RouterProvider router={router} />
            </LocalizationContext.Provider>

        </AppContext.Provider>

    );
}

export { App }