import { ReactElement, useContext, useEffect, useRef, useState } from "react"
import style from "./advanced-search.module.scss";
import { FilterView } from "../Controls/FilterView";
import { Slider } from "@mui/material";
import Select from "react-select";
import { ExchangeOptions, ExchangeOrdering } from "../../misc/SelectOptions";
import { PubSubTopic, SelectStyleFix, SelectStylesFilter } from "../../misc/Constants";
import { Option, OptionTypeDescription } from '../SelectExtension/SelectExtension';
import { Category } from "../../models/Category";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { IsUndefinedOrNull, isEmptyCollection } from "../../misc/Utilities";
import { OptionTypeDescriptionFilter } from '../../misc/ReactSelectHelper';
import classNames from "classnames";
import { Popup } from "../Popup/Popup";
import { GroupSelector } from "../Controls/GroupSelector";
import { Baloon } from "../Controls/Baloon";
import { SearchOptions } from "../../models/SearchOptions";
import { OptionType } from "../../interfaces/OptionType";
import { useLocalStorage } from "../../hooks/UseLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchSettings } from "../Controls/SearchSettings";
import { Message } from "../../misc/EventMessages";


export enum PopupType {
    Groups,
    Settings
}

export interface AdvancedSearchProps extends SearchOptions {
    categories: Category[];
    radiusAvailable: boolean;
}

export const AdvancedSearch = (props: AdvancedSearchProps): ReactElement => {

    const { locationIds, radiusAvailable, categories, categoryId } = props;
    const { localization } = useContext(LocalizationContext);
    const {  groups, localGroups } = useContext(AppContext);
    const [saveToStorage] = useLocalStorage();

    const [selectedGroups, setSelectedGroups] = useState(isEmptyCollection(locationIds) ? [] : locationIds);


    const [tempRadius, setTempRadius] = useState(radiusAvailable ? props.radius : 0);
    const [radius, setRadius] = useState(radiusAvailable ? props.radius : 0);

    const [popupType, setPopupType] = useState<PopupType>(null);

    const [tempQuery, setTempQuery] = useState(props.queryText);
    const [query, setQuery] = useState(props.queryText);

    const [showOffGrid, setShowOffGrid] = useState(props.includeResultsOffgidExchanges);
    const [includeMyExchanges, setIncludeMyExchanges] = useState(props.includeMyExchanges);

    const disanceValues = radiusAvailable ? [10, 20, 50, 100, 300, 500, 1000, 0] : [0];

    let initExchangeOrder = ExchangeOrdering.find(p => p.value === props.order);
    if (IsUndefinedOrNull(initExchangeOrder)) {
        initExchangeOrder = ExchangeOrdering.find(p => p.value === "date");
    }
    const [order, setOrder] = useState<OptionType>(initExchangeOrder);

    let initExchageType = ExchangeOptions.find(p => p.value === props.exchangeType);
    if (IsUndefinedOrNull(initExchageType)) {
        initExchageType = ExchangeOptions.find(p => p.value === "*");
    }
    const [exchangeType, setExchangeType] = useState<OptionType>(initExchageType);

    const allCategory: Category = {
        id: "*",
        labelKey: "allCategories"
    };

    const categoryOptions = [allCategory, ...categories];

    const categoryOptionTypes: OptionTypeDescription[] = categoryOptions.map(c => {
        return {
            value: c.id,
            label: localization[c.labelKey],
            description: localization[c.descriptionKey] ?? "",
            example: localization[c.exampleKey] ?? ""
        }
    });

    let cat = categoryOptionTypes.find(c => c.value === categoryId);

    if (IsUndefinedOrNull(cat)) {
        cat = categoryOptionTypes[0];
    }

    const [selectedCategory, setSelectedCategory] = useState<OptionTypeDescription>(cat);

    const markers = disanceValues.map((val, idx) => {
        return {
            value: idx
        };
    });

    const handleFilterClick = (type: PopupType): void => {
        typeof onFilterClick === "function" && onFilterClick(type);
    };

    const formatSliderLabel = (val: number): number | string | ReactElement => {
        if (val === markers.length - 1) {
            return <span style={{ fontSize: "1.4em" }}>∞</span>;
        }
        else return <span>{disanceValues[val]} km</span>;
    };

    const onFilterClick = (type: PopupType): void => {
        setPopupType(type);
    };

    const onCategoryChange = (opt: OptionTypeDescription): void => {
        setSelectedCategory(opt as any);

        /*
        const model = getSearchModel();
        model.categoryId = opt.value as string;
        PubSub.publish(PubSubTopic.Action, {
            id: Message.SearchOptionsChange,
            data: model
        });

        const sd = getSearchData();
        sd.categoryId = opt.value as string;
        PubSub.publish(PubSubTopic.Action, {
            id: Message.SearchForExchanges,
            data: sd
        });*/
    };

    const onExchangeOrderChange = (opt: OptionType): void => {
        setOrder(opt);
    };

    const onExchangeTypeChange = (opt: OptionType): void => {
        setExchangeType(opt);
    };

    const invokeSearch = (): void => {

        const model: SearchOptions = {
            order: order?.value as string,
            exchangeType: exchangeType?.value as string,
            radius: disanceValues[radius],
            locationIds: selectedGroups,
            queryText: query,
            categoryId: selectedCategory.value as string,
            includeResultsOffgidExchanges: showOffGrid,
            includeMyExchanges: includeMyExchanges
        };

        PubSub.publish(PubSubTopic.Action, {
            id: Message.SearchForExchanges,
            data: model
        });
    };

    const listSelectedGroupNames = (): JSX.Element => {
        const names = localGroups.filter(g => selectedGroups.some(p => p === g.id));

        return <>{
            names.map(n => {
                return <div key={n.id}><span>{n.name}</span></div >
            })
        }</>
    };

    const debounceRef = useRef(null);
    const queryTextChanged = (text: string): void => {
        window.clearTimeout(debounceRef.current);
        setTempQuery(text);
        debounceRef.current = window.setTimeout(() => {
            setQuery(text);
        }, 400);
    };

    let groupSelectionLabel = selectedGroups.length === 1 ? localGroups.find(g => g.id === selectedGroups[0]).name : `${selectedGroups.length} groupes`;
    if(selectedGroups.length === localGroups.length){
        groupSelectionLabel = localization["allGroups"];
    }

    if(selectedGroups.sort().join(',')=== groups.map(p=>p.id).sort().join(',')){
        groupSelectionLabel = localization["myGroups"];
    }


    useEffect(() => {
        // write to storage
        const model: SearchOptions = {
            order: order?.value as string,
            exchangeType: exchangeType?.value as string,
            radius: radius,
            locationIds: selectedGroups,
            queryText: query,
            categoryId: selectedCategory.value as string,
            includeResultsOffgidExchanges: showOffGrid,
            includeMyExchanges: includeMyExchanges
        };
        saveToStorage("search-options", model);

        PubSub.publish(PubSubTopic.Action, {
            id: Message.SearchOptionsChange,
            data: model
        });


    }, [order, selectedGroups, exchangeType, selectedCategory, radius, query, includeMyExchanges, showOffGrid]);

    useEffect(() => {

        const model: SearchOptions = {
            order: order?.value as string,
            exchangeType: exchangeType?.value as string,
            radius: disanceValues[radius],
            locationIds: selectedGroups,
            queryText: query,
            categoryId: selectedCategory.value as string,
            includeResultsOffgidExchanges: showOffGrid,
            includeMyExchanges: includeMyExchanges
        };

        PubSub.publish(PubSubTopic.Action, {
            id: Message.SearchForExchanges,
            data: model
        });

    }, [selectedGroups, exchangeType, selectedCategory, radius, query, includeMyExchanges, showOffGrid])

    const getPopupTitleFromType = (type: PopupType): string => {
        switch (type) {
            case PopupType.Groups:
                return "Sélectionnez les noms de groupes à filtrer";
            case PopupType.Settings:
                return "Paramètres de recherche";
            default:
                return "";
        }
    }

    return (

        <div className={style.main}>

            {
                popupType !== null &&
                <Popup title={getPopupTitleFromType(popupType)} onClose={() => { setPopupType(null) }}>
                    <>
                        {
                            popupType === PopupType.Groups &&
                            <GroupSelector
                                selected={selectedGroups}
                                onSelectionChange={(selection: Array<string>) => {
                                    setSelectedGroups(selection);
                                    setPopupType(null);
                                }} />
                        }
                        {
                            popupType === PopupType.Settings &&
                            <SearchSettings
                                includedMyExchangesInResults={includeMyExchanges}
                                includeResultsFromIndependentUsers={showOffGrid}
                                onSettingsChange={(settings) => {
                                    setIncludeMyExchanges(settings.includedMyExchangesInResults);
                                    setShowOffGrid(settings.includeResultsFromIndependentUsers);
                                    setPopupType(null);
                                }}
                            />
                        }
                    </>
                </Popup>
            }

            <div className={style.filters}>
                <div className={style.f1}>
                    <Baloon text={listSelectedGroupNames()}>
                        <FilterView onClick={() => handleFilterClick(PopupType.Groups)} label={groupSelectionLabel} />
                    </Baloon>
                </div>

                <div className={style.f2}>
                    <Select
                        menuPortalTarget={document.body}
                        value={exchangeType}
                        styles={SelectStylesFilter}
                        onChange={onExchangeTypeChange}
                        options={ExchangeOptions} />
                </div>
                <div className={style.f3}>
                    <Select
                        menuPortalTarget={document.body}
                        components={{ Option }}
                        value={selectedCategory}
                        options={categoryOptionTypes}
                        placeholder={localization["OfferCategoryLabel"]}
                        filterOption={OptionTypeDescriptionFilter}
                        onChange={c => onCategoryChange(c as any)}
                        styles={{ ...SelectStylesFilter }} />
                </div>


                <div className={classNames(style.radiuscontrol, style.f4)}>
                    <div>
                        <Slider
                            className={style.rangeslider}
                            min={0}
                            aria-label="distance selector"
                            max={markers.length - 1}
                            value={tempRadius}
                            marks={markers}
                            step={null}
                            valueLabelDisplay={"off"}
                            valueLabelFormat={formatSliderLabel}
                            onChange={(e, val) => setTempRadius(val as number)}
                            onChangeCommitted={(e, val) => setRadius(val as number)}
                        />
                    </div>
                    {

                        disanceValues[tempRadius] === 0 &&
                        <span className={style.inf}>∞</span>
                    }
                    {
                        disanceValues[tempRadius] !== 0 &&
                        <span>{disanceValues[tempRadius]} km</span>
                    }

                </div>
 

                <div className={style.f5}>
                    <div className={style.textinput}>
                        <input type="text" placeholder={localization["keyWords"]} value={tempQuery} onChange={e => queryTextChanged(e.target.value)} />
                        <button className={tempQuery.length === 0 ? style.hide : null} onClick={e => queryTextChanged("")}>
                            <FontAwesomeIcon icon={"x"} />
                        </button>
                    </div>
                </div>

                <div className={style.f6}>
                    <div className={style.searchctrl}>
                        <button onClick={invokeSearch} className="btn action">
                            <FontAwesomeIcon icon={"search"} />
                        </button>

                        <div className={style.separator}></div>

                        <div className={style.ordergroup}>
                            <FontAwesomeIcon icon="arrow-down-wide-short" />
                            <Select
                                menuPortalTarget={document.body}
                                value={order}
                                onChange={onExchangeOrderChange} styles={SelectStylesFilter} options={ExchangeOrdering} />
                        </div>

                        <button onClick={() => handleFilterClick(PopupType.Settings)} className="btn signup">
                            <FontAwesomeIcon icon={"gear"} />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}