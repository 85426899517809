import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useContext, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { LocalizationContext } from "../../interfaces/AppContext";
import { OptionType } from "../../interfaces/OptionType";
import { SelectStyleFix } from "../../misc/Constants";
import { FindPostalCode, FindPostalCodes } from "../../misc/Requests";
import { IsNullOrWhiteSpace, IsUndefinedOrNull } from "../../misc/Utilities";
import { GeoLocation } from "../../models/GeoLocation";
import { PostalCodeData } from "../../models/PostalCodeData";
import style from "./locationselector.module.scss";
import { GGLMap, GGLMarker } from "../GGLMap";
import classNames from "classnames";

export type SelectionType = "map" | "postalCode" | "ip";

export interface LocationSelectorOptions {
    useMap?: boolean;
    usePostalCode?: boolean;
    useIPDetection?: boolean;

    postalCodePlaceholder?: string;

    onLocationDetectionDenied?: () => void;

    locationLongitude?: number;
    locationLatitude?: number;

    defaultCenterLongitude?: number;
    defaultCenterLatitude?: number;

    mapZoom?: number;

    label?: string;

    onLocationSelect?: (lng: number, lat: number, label: string, type: SelectionType) => void;

    onLocationDelete?: () => void;
}


export const LocationSelector = (props: LocationSelectorOptions): ReactElement => {


    let timeoutRef = useRef(null);

    const { localization } = useContext(LocalizationContext);

    const defaults: LocationSelectorOptions = {
        useMap: true,
        usePostalCode: true,
        useIPDetection: true,
        defaultCenterLatitude: 44,
        defaultCenterLongitude: -73,
        label: ""
    };

    const options = { ...defaults, ...props };

    const hasLocation = !IsUndefinedOrNull(props.locationLongitude) && !IsUndefinedOrNull(props.locationLatitude);
    const point: GeoLocation = hasLocation ? { type: "Point", coordinates: [props.locationLongitude, props.locationLatitude] } : null;

    const [postalCodeSelection, setPostalCodeSelection] = useState(null as OptionType);
    const [location, setLocation] = useState(point);
    const [mapZoom, setMapZoom] = useState(isNaN(props.mapZoom) ? 10 : props.mapZoom);

    const [label, setLabel] = useState(options.label);


    const [type, setType] = useState(null as SelectionType);

    const onPostalCodeSelect = (option: OptionType): void => {

        setPostalCodeSelection(option);

        const val = JSON.parse(option.value as string) as PostalCodeData;

        setLocation({
            type: "Point",
            coordinates: [val.location.coordinates[0], val.location.coordinates[1]] //lng 0 lat 1
        });

        setType("map");

        typeof props.onLocationSelect === "function" &&
            props.onLocationSelect(val.location.coordinates[0], val.location.coordinates[1], label, type);
    };


    const findPostalCodes = async (inputValue: string): Promise<OptionType[]> => {
        type f = (input: string) => Promise<PostalCodeData[]>;

        if (IsNullOrWhiteSpace(inputValue) ?? inputValue.length < 3) {
            return Promise.resolve([]);
        }

        let fn: f = null;

        if (/([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}/.test(inputValue)) {
            fn = FindPostalCode;
        }
        else {
            fn = FindPostalCodes;
        }

        const result = await fn(inputValue);

        return Promise.resolve(result.map(p => {
            return {
                value: JSON.stringify(p),
                label: p.shortCode + " - " + p.locationName
            } as OptionType
        }));
    };

    const requestPosition = (): void => {
        const onSuccess = (position: GeolocationPosition) => {

            setPostalCodeSelection(null);
            setLocation({
                type: "Point",
                coordinates: [position.coords.longitude, position.coords.latitude]
            });

            setType("ip");

            typeof props.onLocationSelect === "function" &&
            props.onLocationSelect(position.coords.longitude, position.coords.latitude, label, type);
        };

        const onError = (error: GeolocationPositionError | null) => {
            typeof props.onLocationDetectionDenied === "function" &&
                props.onLocationDetectionDenied();
        };

        const options: PositionOptions = {
            enableHighAccuracy: true
        };

        navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    };


    const onMapClick = (point: google.maps.LatLng, zoom: number): void => {

        window.clearTimeout(timeoutRef.current);

        setMapZoom(zoom);

        setLocation({
            type: "Point",
            coordinates: [point.lng(), point.lat()]
        });

        setType("map");


        timeoutRef.current = window.setTimeout(() => {
            typeof props.onLocationSelect === "function" &&
                props.onLocationSelect(point.lng(), point.lat(), label, type);
        }, 300);
    };


    const onLocationTitleChange = (value: string): void => {
        window.clearTimeout(timeoutRef.current);
        setLabel(value);
        timeoutRef.current = window.setTimeout(() => {

            typeof props.onLocationSelect === "function" &&
                props.onLocationSelect(location.coordinates[0], location.coordinates[1], value, type);

        }, 700);

    };


    let placeholderLabel = localization["selectTitleLocation"];

    if (location !== null) {
        placeholderLabel += ` (${location.coordinates[1]} ${location.coordinates[0]})`;
    }

    return (
        <div className={style.container}>
            <div className={style.options}>
                <div className={style.leftgroup}>
                    <button
                        title={localization["DetectIp"]}
                        onClick={requestPosition}
                        className={classNames("btn", "action")}>
                        <FontAwesomeIcon icon={"location-crosshairs"} />
                    </button>

                    <div className={style.search}>
                        {
                            options.usePostalCode === true &&
                            <AsyncSelect
                                placeholder={props.postalCodePlaceholder}
                                cacheOptions
                                styles={SelectStyleFix}
                                value={postalCodeSelection}
                                onChange={onPostalCodeSelect}
                                loadOptions={findPostalCodes}
                            />
                        }
                    </div>
                </div>

                <button
                    onClick={() => typeof props.onLocationDelete === "function" && props.onLocationDelete()}
                    title={localization["delete"]}
                    className={classNames(style.delete, "btn", "cancel")}>
                    <FontAwesomeIcon icon={"trash-can"} />
                </button>
            </div>

            <GGLMap
                style={{ height: "250px" }}
                onClick={onMapClick}
                options={
                    {
                        gestureHandling: "cooperative",
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        center: {
                            lng: location === null ? options.defaultCenterLongitude : location.coordinates[0],
                            lat: location === null ? options.defaultCenterLatitude : location.coordinates[1]
                        },
                        zoom: mapZoom
                    }
                }
            >
                {
                    !IsUndefinedOrNull(location) &&
                    <GGLMarker position={new google.maps.LatLng({
                        lat: location.coordinates[1],
                        lng: location.coordinates[0]
                    })} />
                }
            </GGLMap>

            <div className={style.text}>
                <input
                    maxLength={30}
                    value={label}
                    onChange={(e) => onLocationTitleChange(e.target.value)}
                    type="text"
                    placeholder={placeholderLabel} />
            </div>
        </div>
    );

}