import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { SearchResultModel, SearchResults } from '../../components/SearchResults/SearchResults';
import { Category } from '../../models/Category';
import { SearchOptions } from "../../models/SearchOptions";
import { AppContext, LocalizationContext } from '../../interfaces/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IsUndefinedOrNull, IsValidLocation, dateSortDesc } from '../../misc/Utilities';
import { AdvancedSearch } from '../../components/Search/AdvancedSearch';


import style from './home.module.scss';
import { useLocalStorage } from '../../hooks/UseLocalStorage';

export interface HomeProps extends SearchOptions {
    categories: Category[];
    searchResults: Array<SearchResultModel>;
}

const Home = (props: HomeProps): ReactElement => {
    const { searchResults } = props;
    const { person } = useContext(AppContext);
    const { localization } = useContext(LocalizationContext);
    const locationSet = IsValidLocation(person.locationLongitude, person.locationLatitude);
    const [warnLocation, setWarnLocation] = useState(locationSet === false);

    const scrollerRef = useRef();
    const scrollPosition = useRef(0);

    const [setter, getter] = useLocalStorage();


    const clearLocationWarning = (): void => {
        setWarnLocation(false);
    };

    const distanceSorter = (a: SearchResultModel, b: SearchResultModel): number => {
        return a.distanceMeters - b.distanceMeters;
    };

    const dateSorter = (a: SearchResultModel, b: SearchResultModel): number => {
        const dateA = IsUndefinedOrNull(a.exchange.updatedOn) ? a.exchange.publishedOn : a.exchange.updatedOn;
        const dateB = IsUndefinedOrNull(b.exchange.updatedOn) ? b.exchange.publishedOn : b.exchange.updatedOn;
        return dateSortDesc(dateA, dateB);
    };

    const sorter = props.order === "date" ? dateSortDesc : distanceSorter;
    const sordedResults = searchResults.sort(sorter);

    const scrollHandler = (e): void => {
        const elem = scrollerRef.current as HTMLElement;
        scrollPosition.current = elem.scrollTop;
    };

    useEffect(() => {
        const elem = scrollerRef.current as HTMLElement;

        const pos = getter("results-scroll-pos");

        if (!IsUndefinedOrNull(pos) && !isNaN(pos)) {
            elem.scrollTop = pos;
        }

        PubSub.subscribe("result.events", () => {
            elem.scrollTop = 0;
        });
     
        return (): void => {
            setter("results-scroll-pos", scrollPosition.current);
        }
    }, []);



    return (


        <div className={style.container}>
            {
                warnLocation &&
                <div className={style.warning}>
                    <button onClick={clearLocationWarning}>
                        <FontAwesomeIcon icon={"x"} />
                    </button>
                    <p>{localization["locationNotSetWarning"]} <Link to={"/profile/edit"}>{localization["addLocation"]}</Link></p>
                </div>
            }
            {
                <AdvancedSearch
                    radiusAvailable={locationSet}
                    {...props} />
            }


            <div onScroll={scrollHandler} className={style.results} ref={scrollerRef}>
                <SearchResults radiusAvailable={locationSet} results={sordedResults} />

            </div>
        </div >
    );
}

export { Home }