import { ReactElement, useContext, useEffect, useState } from "react";
import style from "./usermanage.module.scss";
import axios from "axios";
import { app } from "../../..";
import { StatusCodes } from "http-status-codes";
import { Person } from "../../../models/Person";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalizationContext } from "../../../interfaces/AppContext";
import classNames from "classnames";


export const UserManagement = (): ReactElement => {


    const [searchQuery, setSearchQuery] = useState("");

    const [people, setPeople] = useState<Person[]>([]);

    const { localization } = useContext(LocalizationContext);

    const nav = useNavigate();


    useEffect(() => {

        const rq = axios.get(`${app.apiBasePath}/admin/users/list`);
        rq.then(result => {
            if (result.status === StatusCodes.OK) {
                setPeople(result.data);
            }
        })
    }, []);


    const exp = new RegExp(searchQuery, "i");
    const finder = (person: Person) => {
        if (searchQuery.length < 2) return person;
        else {
            return exp.test(person.firstName) || exp.test(person.lastName);
        }
    };

    const filteredPeople = people.filter(finder);

    return (

        <div className={classNames(style.container, "form")}>
            <h2>{localization["userAdministration"]}</h2>
            <div className={style.filters}>
                <div>
                    <div className={style.filterfield}>
                        <input type="text" className="form-control"
                            onChange={e => setSearchQuery(e.target.value)}
                            placeholder={localization["search"]}
                            value={searchQuery} />
                        <button onClick={() => setSearchQuery("")}>
                            <FontAwesomeIcon icon="x" />
                        </button>
                    </div>
                </div>

                <button className={classNames("btn", "save2", style.b)} onClick={() => nav("create")}>
                    <FontAwesomeIcon icon="plus" />
                    <FontAwesomeIcon icon="user" />
                </button>


            </div>

            <div className={style.userlist}>
                <ul>
                    {
                        filteredPeople
                            .sort((a: Person, b: Person) => { return a.firstName.localeCompare(b.firstName) })
                            .map(p => {
                                return <li key={p.personId}><Link to={`/admin/users/${p.personId}`}>{p.firstName} {p.lastName}</Link></li>
                            })
                    }
                </ul>
            </div>

        </div>
    );
}