import React, { useContext, useState } from 'react';
import { EmptyFrame } from '../../components/EmptyFrame';
import { Uploader, SupportedFiles, UploadResponse, UploaderError } from '../../components/Uploader';
import { CanadaTimeZones, FranceTimeZones, PubSubTopic } from '../../misc/Constants';
import { Person } from '../../models/Person';
import { ProgressBar } from '../../components/ProgressBar'
import axios from 'axios';
import { StorageType } from '../../models/Enums';
import { UploadModel } from '../../models/UploadForm';
import { NotificationView, NotificationType } from '../../components/NotificationView';
import { Link, useNavigate } from 'react-router-dom';
import { Message } from '../../misc/EventMessages';
import { Image } from '../../components/Image';
import { AppContext, LocalizationContext } from '../../interfaces/AppContext';
import Select from 'react-select';
import { CountryList } from '../../misc/Constants';
import { IsNullOrWhiteSpace, IsUndefinedOrNull, IsValidLocation } from '../../misc/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeoLocation } from '../../models/GeoLocation';
import { LocationSelector, SelectionType } from '../../components/LocationSelector/LocationSelector';

import style from './profile.module.scss';
import { app } from '../..';
import classNames from 'classnames';


type ProfileDeleteStep = "none" | "warn";

const ProfileEdit = (): JSX.Element => {

    const nav = useNavigate();

    const { person } = useContext(AppContext);
    const { localization } = useContext(LocalizationContext);
    const v = localization;
    const mainContext = useContext(AppContext);

    const [isDirty, setIsDirty] = useState(false);
    const [primaryContact, setPrimaryContact] = useState(person.primaryNumber ?? "");
    const [secondaryContact, setSecondaryContact] = useState(person.secondaryNumber ?? "");
    const [email, setEmail] = useState(person.email ?? "");
    const [firstName, setFirstName] = useState(person.firstName ?? "");
    const [lastName, setLastName] = useState(person.lastName ?? "");
    const [displayName, setDisplayName] = useState(person.displayName ?? "");
    const [isUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    const [corruptedImageError, setCorruptedImageError] = useState(false);
    const [countryCode, setCountryCode] = useState(mainContext.person.countryCode);
    const [timeZone, setTimeZone] = useState(mainContext.person.timeZone);



    const hasLocationSet = IsValidLocation(person.locationLatitude, person.locationLatitude);

    const emptyPoint: GeoLocation = { type: "Point", coordinates: [0, 0] };

    const point: GeoLocation = hasLocationSet ? { type: "Point", coordinates: [person.locationLongitude, person.locationLatitude] } : emptyPoint;

    const [location, setLocation] = useState(point);
    const [locationSelectionType, setLocationSelectionType] = useState(null as SelectionType);
    const [locationLabel, setLocationLabel] = useState(IsNullOrWhiteSpace(person.locationLabel) ? "" : person.locationLabel);

    const [profileDeleteState, setProfileDeleteState] = useState<ProfileDeleteStep>("none");

    const [deleteInput, setDeleteInput] = useState("");
    const [deleteChallengeFail, setDeleteChallengeFail] = useState<boolean>(null);
    const [deleteFailCounter, setDeleteFailCounter] = useState(0);




    let uploadForm: UploadModel = {
        storageType: StorageType.Avatar
    };

    let timeZoneList = CanadaTimeZones;

    if (countryCode === "FR") {
        timeZoneList = FranceTimeZones;
    }

    const UpdateProfile = (): void => {

        setErrorMessage('');
        setIsUpdating(true);
        setIsDirty(false);

        const profileModel = {
            firstName: firstName,
            lastName: lastName,
            displayName: displayName,
            email: email,
            primaryNumber: primaryContact,
            secondaryNumber: secondaryContact,
            timeZone: -5,
            latitude: null,
            longitude: null,
            locationLabel: locationLabel
        };

        if (!IsUndefinedOrNull(location)) {
            profileModel.longitude = location.coordinates[0];
            profileModel.latitude = location.coordinates[1];
        }

        let request = axios.post(`${app.apiBasePath}/profile/updateprofile`, profileModel);

        request.then(result => {

            if (result.data.accepted) {
                setIsUpdating(false);
                setIsDirty(false);

                PubSub.publish(PubSubTopic.Action, {
                    id: Message.UpdateProfile,
                    data: profileModel
                });

                window.setTimeout(() => {

                    nav('/profile');
                }, 0);
            }
            else {
                setErrorMessage(result.data.error);
                setIsUpdating(false);
                setIsDirty(false);
            }
        });

    };

    const CancelChange = (): void => {
        nav('/profile');
    };

    const OnAddProfilePicture = (): void => {
        if (isUploading)
            return;

        PubSub.publish(PubSubTopic.Component + ".uploader", { event: "uploader-browse" });
    };

    const OnUploaderError = (error: UploaderError): void => {
        if (error.code === -600) {
            setFileSizeError(true);
        }
        if (error.code === -700) {
            setFileSizeError(true);
        }
    };

    const OnAvatarUploaded = (data: UploadResponse): void => {

        if (data.accepted === true) {

            var result = axios.post(`${app.apiBasePath}/profile/updateavatar`, {
                fileId: data.fileId
            });

            result.then((response) => {
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.UpdateAvatarFileId,
                    data: {
                        fileId: data.fileId
                    }
                });
            });
        }
        else {
            if (data.errorCode === 601) {
                setCorruptedImageError(true);
            }
        }
    };

    const RemoveAvatar = (fileId: string): void => {

        let request = axios.post(`${app.apiBasePath}/profile/removeavatar`, {
            fileId: fileId
        });

        request.then(result => {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.UpdateAvatarFileId,
                data: {
                    fileId: null
                }
            });
        });
    };

    const onLocationSelect = (lng: number, lat: number, label: string, type: SelectionType): void => {

        setIsDirty(true);
        setLocationLabel(label);
        setLocationSelectionType(type);
        setLocation({
            type: "Point",
            coordinates: [lng, lat]
        });
    };


    const deleteProfileClick = (): void => {
        if (profileDeleteState === "none") {
            setProfileDeleteState("warn");
        }
        if (profileDeleteState === "warn") {
            if (deleteInput.length > 2) {
                const rq = axios.post(`${app.apiBasePath}/auth/validatepwd`, {
                    currentPassword: deleteInput
                });

                rq.then(p => {
                    if (p.data.accepted) {

                        const rq2 = axios.post(`${app.apiBasePath}/profile/delete`, {
                            password: deleteInput
                        }).then(d => {
                            if (d.data.accepted) {
                                window.location.href = `${app.apiBasePath}/auth/signout`;
                            }
                        })
                    }
                    else {

                        if (deleteFailCounter === 2) {
                            window.location.href = `${app.apiBasePath}/auth/signout`;
                        }

                        setDeleteChallengeFail(true);
                        setDeleteFailCounter(deleteFailCounter + 1);
                    }
                });

                rq.catch(() => {
                    window.location.reload();
                })
            }
        }
    };

    const removeLocation = (): void => {
        setIsDirty(true);
        setLocationLabel("");
        setLocation(emptyPoint);
    };

    const enableLocationAdd = (): void => {
        setIsDirty(true);
        setLocationLabel("");
        setLocation({
            type: "Point",
            coordinates: [-74, 45]
        });
    };

    const validLocation = IsValidLocation(location.coordinates[0], location.coordinates[1]);

    return (

        <div className="page-container">
            <div className={style.scroll}>
                <Uploader
                    uploadMetadata={uploadForm}
                    monitorDispach={true}
                    url={`${app.apiBasePath}/upload/avatar`}
                    supportedFiles={SupportedFiles.Images}
                    maxFileSizeMB={22}
                    onFileUploaded={OnAvatarUploaded}
                    progressBarId="__progressbr01"
                    onUploadComplete={(files) => { setIsUpdating(false); }}
                    onBeforeUpload={(file) => { setIsUpdating(true); }}
                    onError={OnUploaderError}
                />

                <div className="form profile">
                    <div className="row">
                        <div className="flex w">
                            <div>
                                <h1>{v["ProfileEditHeading"]}</h1>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <Link to="/profile/changepwd" className="small-link">{v["ChangePassword"]}</Link>
                                </div>
                            </div>
                            <div className={style["upload-container"]}>
                                {
                                    !IsNullOrWhiteSpace(person.avatarFileId) &&
                                    <Image
                                        onDelete={RemoveAvatar}
                                        fileId={person.avatarFileId}
                                        altText={person.displayName} />
                                }
                                {
                                    IsNullOrWhiteSpace(person.avatarFileId) &&
                                    <EmptyFrame
                                        warn={fileSizeError || corruptedImageError}
                                        title={"Add profile picture"}
                                        onFrameClick={OnAddProfilePicture}
                                    />
                                }
                                {
                                    fileSizeError &&
                                    <NotificationView type={NotificationType.Error}>
                                        <div>
                                            <p>Please select smaller image</p>
                                            <p style={{ marginBottom: '5px' }}>Limit: 20MB</p>
                                            <a onClick={() => setFileSizeError(false)} className="pointer">hide</a>
                                        </div>
                                    </NotificationView>
                                }
                                {
                                    corruptedImageError &&
                                    <NotificationView type={NotificationType.Error}>
                                        <div>
                                            <p style={{ marginBottom: '5px' }}>Please select another image</p>
                                            <a onClick={() => setCorruptedImageError(false)} className="pointer">hide</a>
                                        </div>
                                    </NotificationView>
                                }
                                {
                                    isUploading &&
                                    <ProgressBar domId="__progressbr01" />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row separator">
                        <h3>{v["GeneralInformation"]}</h3>
                    </div>

                    <div className="row">
                        <label>{v["DisplayName"]} <span className="required">*</span></label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            onChange={(e) => {
                                setDisplayName(e.target.value);
                                setIsDirty(true);
                            }}
                            value={displayName}
                            className="form-control" />
                    </div>

                    <div className="row">
                        <label>{v["FirstName"]}</label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setIsDirty(true);
                            }}
                            value={firstName}
                            className="form-control" />
                    </div>

                    <div className="row extra-space">
                        <label>{v["LastName"]}</label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                setIsDirty(true);
                            }}
                            value={lastName}
                            className="form-control" />
                    </div>


                    <div className="row extra-space">
                        <h3>{v["Location"]}</h3>

                        <div className={style.geo}>
                            {
                                !validLocation &&
                                <button onClick={enableLocationAdd} className={classNames("btn", "save", style.createlocation)}>
                                    <FontAwesomeIcon icon="plus" />
                                    {localization["chooseLocation"]}
                                </button>
                            }
                        </div>

                        {
                            !IsUndefinedOrNull(location) &&
                            validLocation &&
                            <LocationSelector
                                label={locationLabel}
                                locationLongitude={location === null ? null : location.coordinates[0]}
                                locationLatitude={location === null ? null : location.coordinates[1]}
                                onLocationDetectionDenied={() => console.warn("location detection denied")}
                                onLocationSelect={onLocationSelect}
                                onLocationDelete={removeLocation}
                                postalCodePlaceholder={v["LocationSearchPlaceholder"]}
                            />

                        }


                    </div>


                    <div className="row separator">
                        <h3>{v["TimeZone"]}</h3>
                    </div>

                    <div className="row extra-space">
                        <div className={style.timezones}>
                            <Select
                                onChange={(opt) => {
                                    setCountryCode(opt.value as string);
                                    setIsDirty(true);
                                }}
                                options={CountryList}
                                value={CountryList.filter(c => c.value === countryCode)} />
                            {
                                <Select
                                    onChange={(opt) => {
                                        setTimeZone(opt.value as number);
                                        setIsDirty(true);
                                    }}
                                    value={timeZoneList.filter(c => c.value === timeZone)}
                                    options={timeZoneList} />
                            }
                        </div>
                    </div>

                    <div className="row separator">
                        <h3>{v["ContactInformation"]}</h3>
                    </div>

                    <div className="row">
                        <label>{v["PrimaryNumber"]}</label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            onChange={(e) => {
                                setPrimaryContact(e.target.value);
                                setIsDirty(true);
                            }}
                            value={primaryContact}
                            className="form-control" />
                    </div>

                    <div className="row">
                        <label>{v["SecondaryNumber"]}</label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            onChange={(e) => {
                                setSecondaryContact(e.target.value);
                                setIsDirty(true);
                            }}
                            value={secondaryContact}
                            className="form-control" />
                    </div>

                    <div className="row">
                        <label>{v["EmailAddress"]}</label>
                        <input type="text"
                            autoComplete="chrome-off"
                            disabled={isUpdating}
                            readOnly={true}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setIsDirty(true);
                            }
                            }
                            value={email}
                            className="form-control readonly" />
                    </div>


                    <div className="row">
                        <div className="flex buttons w">
                            <button className="btn action" onClick={CancelChange}>
                                {v["return"]}
                            </button>

                            {
                                isDirty &&
                                !isUpdating &&
                                <button className="btn save" onClick={UpdateProfile}>
                                    {v["SaveChanges"]}
                                </button>
                            }

                        </div>
                    </div>
                    <hr />
                    {
                        profileDeleteState === "warn" &&
                        <div>
                            <p className={style.warning}>{localization["noGoingBack"]}</p>

                        </div>
                    }
                    <div className={classNames("row", style.deleterow)}>

                        {
                            profileDeleteState === "warn" &&
                            <div>
                                <label>{localization["TypePasswordPlaceholder"]}</label>


                                <input value={deleteInput}
                                    autoComplete="one-time-code"
                                    onChange={e => setDeleteInput(e.target.value)}
                                    type="password" className={classNames("form-control", deleteChallengeFail === true ? style.inputwarn : null)} />
                            </div>
                        }


                        <button className="btn cancel" onClick={deleteProfileClick}>
                            {profileDeleteState === "none" && localization["deleteAccount"]}
                            {profileDeleteState === "warn" && localization["confimDelete"]}
                        </button>


                    </div>
                    <div>
                        {
                            deleteChallengeFail &&
                            <p className={style.warning}>{localization["AuthFailureMsg"]}</p>
                        }

                    </div>
                    <div className="row">
                        {
                            !isDirty &&
                            isUpdating &&
                            <NotificationView type={NotificationType.Info}>
                                <p>saving changes...</p>
                            </NotificationView>
                        }
                        {
                            !IsNullOrWhiteSpace(errorMessage) &&
                            <NotificationView type={NotificationType.Error}>
                                <p>{errorMessage}</p>
                            </NotificationView>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export { ProfileEdit }