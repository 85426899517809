import { LocalEvent, Store } from '../core/interfaces/CoreInterfaces';
import { EventRouter } from '../core/EventRouter';
import { LoginEventsHandler } from '../handlers/LoginEventsHandler';
import { MainHandler } from '../handlers/MainHandler';
import { AppState } from '../interfaces/AppState';
import { IJeuContext } from '../interfaces/IJeuContext';
import PubSub from 'pubsub-js';
import { PubSubTopic } from '../misc/Constants';
import { Category } from '../models/Category';
import { ExchangeHandler } from '../handlers/ExchangeHandler';
import { FavoriteExchange } from '../models/FavoriteExchange';
import { SocketMessageHandler } from '../handlers/SocketMessageHandler';
import { LocalStorageService } from '../services/LocalStorageService';
import { IsNullOrWhiteSpace, IsUndefinedOrNull, StringIsNullOrEmpty, isEmptyCollection } from '../misc/Utilities';
import { SearchOptions } from '../models/SearchOptions';

class MainStore implements Store {

    localStorageService: LocalStorageService = null;

    state: AppState = {
        person: null,
        groups: [],
        localGroups: [],
        categories: [],
        exchanges: [],
        favoriteExchanges: [],
        groupPath: [],
        localization: {},
        userDebugQuery: "",
        userManifest: null,
        notifications: [],
        searchOptions: {
            queryText: "",
            radius: 5,
            categoryId: "",
            locationIds: [],
            exchangeType: "",
            order: "",
            includeResultsOffgidExchanges: false,
            includeMyExchanges: false
        },
        searchResults: []
    };

    constructor() {
        this.initialize();
    }

    initialize(): void {

        let router = new EventRouter({ isDebug: false });

        let lh = new LoginEventsHandler();
        let mh = new MainHandler();
        let eh = new ExchangeHandler();
        let smh = new SocketMessageHandler();

        router.addHandler(lh);
        router.addHandler(mh);
        router.addHandler(eh);
        router.addHandler(smh);

        PubSub.subscribe(PubSubTopic.Action, async (msg, data: LocalEvent) => {

            let result = router.route(data, this.state);

            if (result instanceof Promise) {
                await result && this.emitChanges();
            }
            else {
                result === true && this.emitChanges();
            }
        });
    }

    setUserContext(context: IJeuContext): void {
        this.state.person = context.person;
        this.state.groups = context.groups;
        this.state.groupPath = context.groupPath;
        this.state.localGroups = context.localGroups;
    }

    setCategories(categories: Category[]): void {
        this.state.categories = categories;
    }

    setFavoriteExchange(favorites: FavoriteExchange[]): void {
        this.state.favoriteExchanges = favorites;
    }

    emitChanges(): void {
        PubSub.publish(PubSubTopic.Changes, this.state);
    }

    initializeStorage(): void {
        this.localStorageService = new LocalStorageService({
            person: this.state.person,
            groups: this.state.groups,
            groupPath: this.state.groupPath,
            favorites: this.state.favoriteExchanges,
            localGroups: this.state.localGroups
        });
    }

    initializeSearchOptions(): void {

        if (this.localStorageService === null) {
            throw new TypeError("invalid operation - call initializeStorage first");
        }

        let searchOptions = this.localStorageService.getFromStorage<SearchOptions>("search-options");

        //console.log(searchOptions, "in-storage");

        this.state.searchOptions.radius = isNaN(searchOptions?.radius) ? 3 : searchOptions.radius;
        this.state.searchOptions.exchangeType = StringIsNullOrEmpty(searchOptions?.exchangeType) ? "*" : searchOptions.exchangeType;

        this.state.searchOptions.order = StringIsNullOrEmpty(searchOptions?.order) ? "date" : searchOptions.order;

        let selectedGroups: Array<string> = [];
        if (isEmptyCollection(searchOptions?.locationIds)) {
            selectedGroups = this.state.groups.map(p => p.id);
        }
        else {
            // assert every item is valid
            selectedGroups = searchOptions.locationIds.filter(item => this.state.localGroups.some(g => g.id === item));

            if (selectedGroups.length === 0) {
                selectedGroups = this.state.groups.map(p => p.id);
            }
        }

        this.state.searchOptions.includeMyExchanges = searchOptions?.includeMyExchanges ?? true;
        this.state.searchOptions.includeResultsOffgidExchanges = searchOptions?.includeResultsOffgidExchanges ?? false;

        this.state.searchOptions.locationIds = selectedGroups;
        this.state.searchOptions.queryText = IsNullOrWhiteSpace(searchOptions?.queryText) ? "" : searchOptions.queryText;

        let selectedCategoryId = searchOptions?.categoryId;

        if (IsUndefinedOrNull(selectedCategoryId)) {
            this.state.searchOptions.categoryId = "*";
        }
        else {
            this.state.searchOptions.categoryId = selectedCategoryId;
        }
    }
}

export { MainStore };