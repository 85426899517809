import { MouseEvent, ReactElement, useContext } from "react";
import { LocalizationContext } from "../../interfaces/AppContext";
import { FavoriteExchange } from "../../models/FavoriteExchange";
import { Link, useNavigate } from "react-router-dom";
import style from "./favorites.module.scss";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/EventMessages";
import { app } from "../..";
import axios from "axios";
import { StatusCodes } from "http-status-codes";
import noimg from "../../images/no-image.jpg";
import { IsNullOrWhiteSpace } from "../../misc/Utilities";

export interface FavoritesPageProps {
    items: FavoriteExchange[];
}

export const FavoritesPage = (props: FavoritesPageProps): ReactElement => {

    const { localization } = useContext(LocalizationContext);

    const { items } = props;

    const nav = useNavigate();

    const removeFavorite = (event: MouseEvent, item: FavoriteExchange): void => {

        event.stopPropagation();

        const request = axios.post(`${app.apiBasePath}/profile/favorites/exchanges`, {
            exchangeId: item.exchangeId,
            addToFavorite: false
        });

        request.then(result => {
            if (result.status === StatusCodes.OK) {
                PubSub.publish(PubSubTopic.Action, {
                    id: Message.RemoveFavoriteExchange,
                    data: item.exchangeId
                });
            }
        });

    };

    const navigateToItem = (event: MouseEvent, fav: FavoriteExchange): void => {
        nav(`/exchanges/${fav.exchangeId}`);
    };

    return (
        <div className={style.container}>
            <div className="form">
                <h1>{localization["myFavotires"]}</h1>

                <div className={style.list}>
                    {
                        items.map(fav => {
                            return (


                                <div className={style.fav} onClick={e => navigateToItem(e, fav)} key={fav.exchangeId}>
                                    <div className={style.f}>
                                        <div className={style.h}>
                                            <h3>{fav.exchangeTitle}</h3>
                                            <div>
                                                <FontAwesomeIcon icon="x" onClick={x => removeFavorite(x, fav)} />
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                !IsNullOrWhiteSpace(fav.fileId) &&
                                                <img draggable={false} src={`${app.apiBasePath}/assets/image/thumb/${fav.fileId}`} alt="image non trouvé" />
                                            }
                                            {
                                                IsNullOrWhiteSpace(fav.fileId) &&
                                                <img src={noimg} />
                                            }
                                        </div>
                                    </div>

                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    );
};