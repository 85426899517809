import { OptionType } from "../interfaces/OptionType";



export const ExchangeOptions: OptionType[] = [
    {
        value: "offer",
        label: "Offres"
    },
    {
        value: "demand",
        label: "Demandes"
    }, {
        value: "*",
        label: "Tous les types"
    }
];

export const ExchangeOrdering: OptionType[] = [
    {
        value: "distance",
        label: "par distance"
    },
    {
        value: "date",
        label: "par date"
    }
];