import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import classNames from "classnames";
import { StatusCodes } from "http-status-codes";
import { ReactElement, useState } from "react";
import ReactLoading from "react-loading";
import { app } from "../..";
import { PubSubTopic } from "../../misc/Constants";
import { Message } from "../../misc/EventMessages";
import style from "./favorites.module.scss";
import { StringIsNullOrEmpty } from "../../misc/Utilities";

import { faStar as star1 } from '@fortawesome/free-regular-svg-icons';
import { faStar as star2 } from '@fortawesome/free-solid-svg-icons';

export interface FavoritesProps {
    isSet: boolean;
    resourceId: string;
    title?: string;
}

export const Favorites = (props: FavoritesProps): ReactElement => {

    const [loading, setLoading] = useState(false);

    const { isSet, resourceId, title } = props;


    const toggleFavorite = async (): Promise<void> => {
        setLoading(true);
        try {
            const result = await axios.post(`${app.apiBasePath}/profile/favorites/exchanges`, {
                exchangeId: resourceId,
                addToFavorite: !isSet
            });

            if (result.status === StatusCodes.OK) {
                let msg = typeof result.data === "string" ? Message.RemoveFavoriteExchange : Message.AddFavoriteExchange;
                PubSub.publish(PubSubTopic.Action, {
                    id: msg,
                    data: result.data
                });
            }
            setLoading(false);
        }
        catch { setLoading(false); }
    };

    if (loading) {
        return <ReactLoading type={"spin"} color={"#5270F0"} height={16} width={16} />
    }

    return (
        <FontAwesomeIcon
            title={StringIsNullOrEmpty(title) ? "favoris" : title}
            onClick={toggleFavorite}
            className={classNames(style.fav, isSet ? style.set : null)}
            icon={isSet ? star2 : star1} />
    );

};