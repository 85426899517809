import axios from "axios";
import { StatusCodes } from "http-status-codes";
import { ReactElement, useEffect, useState, useContext, useRef } from "react"
import { useNavigate, useParams } from "react-router"
import { app } from "../..";
import notfound from "../../images/notfound.png";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import ReactLoading from "react-loading";
import { Category } from "../../models/Category";
import classNames from "classnames";
import { AttachmentType } from "../../models/Enums";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { ExchangeDetails } from "../../models/ExchangeDetails";
import 'swiper/scss';
import 'swiper/scss/navigation';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GGLMap, GGLMarker } from "../../components/GGLMap";
import { ExchangeSideInfo } from "./ExchangeSideInfo";
import style from "./exchange.module.scss";
import './editor-styles.scss'


export interface ExchangeViewProps {
    categories: Category[];
}

export const ExchangeView = (props: ExchangeViewProps): ReactElement => {

    const { id } = useParams();
    const { localization } = useContext(LocalizationContext);
    const { person } = useContext(AppContext);
    const [exchangeInfo, setExchange] = useState(null as ExchangeDetails);
    const [hasContent, setHasContent] = useState(null as boolean);
    const [busy, setBusy] = useState(true);
    const [mapZoom, setMapZoom] = useState(10);
    const [showMap, setShowMap] = useState(false);


    const [viewsCount, setViewsCount] = useState(0);

    const nav = useNavigate();

    const cc = props.categories;

    let location: google.maps.LatLng = null;

    const containerRef = useRef();

    if (exchangeInfo !== null) {
        const coord = exchangeInfo.exchange.location.coordinates;

        if (coord.length > 0) {
            if (coord[0] !== 0 && coord[1] !== 0) {
                location = new google.maps.LatLng({
                    lat: coord[1],
                    lng: coord[0]
                });
            }
        }
    }

    useEffect(() => {
        axios.get(`${app.apiBasePath}/exchange/views?exchangeId=${id}`)
            .then(result => {
                setViewsCount(result.data as number);
            });
    }, []);


    useEffect(() => {

        const request = axios.get(`${app.apiBasePath}/exchange/view/${id}`);

        request.then(result => {
            setBusy(false);
            if (result.status === StatusCodes.NO_CONTENT) {
                setHasContent(false);
            }
            else if (result.status === StatusCodes.OK) {
                setHasContent(true);
                setExchange(result.data as ExchangeDetails);
            }
            else {
                setHasContent(false);
                console.warn(result);
            }
        });
    }, [id]);

    if (!busy && hasContent !== true) {
        return (
            <div className={classNames(style.container, style.white)}>

                <div className={style.nocontent}>
                    <h3>{localization["nocontent"]}</h3>
                    <img src={notfound} alt="rien trouvé" />
                </div>
            </div>
        );
    }

    if (busy && hasContent !== true) {
        return (
            <div className={style.container}>
                <div className={style.nocontent}>
                    <ReactLoading type={"spin"} color={"#5270F0"} height={40} width={40} />
                </div>
            </div>
        );
    }


    const c1 = cc.find(p => p.id === exchangeInfo.exchange.categoryId);


    const imgs = exchangeInfo.exchange.attachments.filter(a => a.attachmentType === AttachmentType.Image).map(a => {
        return {
            id: a.fileId,
            src: `${app.apiBasePath}/assets/image/${a.fileId}`,
            isSelected: false
        }
    });

    const onMapClick = (point: google.maps.LatLng, zoom: number): void => {
        setMapZoom(zoom);
    }

    return (
        <div className={style.container}>
            <div className={style.exchange}>
                <div className={style.topcontrols}>
                    <div className={style.left}>
                        <div>
                            <Link className={classNames(style.button, style.search)} to={"/"}>
                                <FontAwesomeIcon icon={"magnifying-glass"} />
                            </Link>
                        </div>
                        {
                            person.personId === exchangeInfo.exchange.publishedBy &&
                            <div>
                                <Link className={classNames(style.button, style.edit)} to={`/post/exchange/${exchangeInfo.exchange.id}`}>
                                    <FontAwesomeIcon icon={"pen"} />
                                </Link>
                            </div>
                        }
                        {
                            false &&
                            person.personId !== exchangeInfo.exchange.publishedBy &&
                            <button className={style.offerbtn}>Offrir</button>
                        }
                    </div>
                    <div className={style.right}>
                        <div>
                            {
                                exchangeInfo.exchange.exchangeType === "demande" &&
                                <div className={classNames(style.tag, style.demande)}>demande</div>
                            }
                            {
                                exchangeInfo.exchange.exchangeType === "offer" &&
                                <div className={classNames(style.tag, style.offer)}>offre</div>
                            }
                        </div>
                    </div>

                </div>

                <div className={style.body}>
                    <div className={style.main}>
                        <div className={style.titlearea}>
                            <div className={style.title}>
                                <h1>{exchangeInfo.exchange.title}</h1>
                                <span className={style.c}>{localization[c1?.labelKey]}</span>
                            </div>
                        </div>

                        <div className={style.imagecollection}>
                            {
                                imgs.length > 0 &&
                                <Swiper className={style.images} navigation={true} modules={[Navigation]}>
                                    {
                                        imgs.map(img => {
                                            return (
                                                <SwiperSlide key={img.id}>
                                                    {
                                                        <img src={img.src} alt="img" />
                                                    }
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            }
                        </div>

                        <div className={style.description}>
                            <h4>Description</h4>
                            <div className={style.html}>
                                <div dangerouslySetInnerHTML={{ __html: exchangeInfo.exchange.description }}></div>
                            </div>
                        </div>

                    </div>
                    <div className={style.side}>
                        <ExchangeSideInfo
                            exchangeInfo={exchangeInfo}
                            viewsCount={viewsCount}
                            hasLocation={location !== null}
                            onViewLocation={showMap ? null : () => {
                                setShowMap(true);
                            }} />

                        {
                            showMap &&
                            <div className={style.mapwrapper}>
                                <div className={style.map}>
                                    {
                                        location !== null &&
                                        <GGLMap
                                            onClick={onMapClick}
                                            style={{ height: "100%" }}
                                            options={
                                                {
                                                    gestureHandling: "cooperative",
                                                    mapTypeControl: false,
                                                    streetViewControl: false,
                                                    fullscreenControl: false,
                                                    center: location,
                                                    zoom: mapZoom
                                                }
                                            }>
                                            <GGLMarker position={location} />
                                        </GGLMap>
                                    }
                                    {
                                        location === null &&
                                        <p>no map</p>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}